import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'QUBE',
  production: false,
  hmr: false,
  name: 'Acceptance',
  baseUrl: 'https://qube-acceptance.staging.emendis.nl/api',
  sentryUrl: 'https://450eae86b7a74243a001cdd7d50f409a@o4504360297496576.ingest.sentry.io/4504377899483136',
  databaseName: 'qube.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'https://mijn.qube.acceptatie.emendis.nl',
  tenantKeyHostIndex: 1,
};
